import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbarAlertState } from '../../repository/common/commonSlice';

export const useSnakeBarAlert = () => {
    const dispatch = useDispatch();
    const snackbarState = useSelector((state) => state.common.snackbarAlertState);

    const removeBookmarkAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "북마크에서 삭제했어요.",
            actionIcon: null,
            actionRoute: ""
        }));
    }

    const addBookmarkAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "북마크에 추가했어요!",
            actionIcon: "/assets/icons/white_right_arrow.png",
            actionRoute: "/bookmarks?type=post"
        }));
    }

    const addLocationBookmarkAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "내장소에 추가했어요!",
            actionIcon: "/assets/icons/white_right_arrow.png",
            actionRoute: "/bookmarks?type=location"
        }));
    }

    const removeLocationBookmarkAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "내장소에서 삭제했어요!",
            actionIcon: null,
            actionRoute: ""
        }));
    }

    const handleLoginAlert = () => {
        dispatch(setSnackbarAlertState({
          open: true,
          message: "로그인이 필요해요. 👉🏻",
          actionIcon: "/assets/icons/login_icon.png",
          actionRoute: "/login"
        }));
    };

    const handleSecessionAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "⚠️ 탈퇴한 계정이에요.",
            actionIcon: null,
            actionRoute: ""
        }));
    };

    const handleCloseSnackbar = () => {
        dispatch(setSnackbarAlertState({
            open: false,
            message: '',
            actionIcon: null,
            actionRoute: ''
        }));
    };

    const excetpionContentAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "⚠️ 작품에 대한 설명을 입력해주세요.",
            actionIcon: null,
            actionRoute: ''
        }));
    };

    const exceptionImageAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "⚠️ 작품 사진을 추가해주세요.",
            actionIcon: null,
            actionRoute: ''
        }));
    }

    const exceptionArtAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "⚠️ 작품 정보를 입력해주세요.",
            actionIcon: null,
            actionRoute: ''
        }));
    }

    const exceptionTitleAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "⚠️ 작품의 제목을 입력해주세요.",
            actionIcon: null,
            actionRoute: ''
        }));
    }

    const exceptionArtistAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "⚠️ 작가의 이름을 입력해주세요.",
            actionIcon: null,
            actionRoute: ''
        }));
    }

    const exceptionShareAlert = () => {
        dispatch(setSnackbarAlertState({
            open: true,
            message: "⚠️ Web Share API가 지원되지 않습니다.",
            actionIcon: null,
            actionRoute: ''
        }));
    }

    return {
        snackbarState,
        removeBookmarkAlert,
        addBookmarkAlert,
        addLocationBookmarkAlert,
        removeLocationBookmarkAlert,
        handleLoginAlert,
        handleSecessionAlert,
        handleCloseSnackbar,
        excetpionContentAlert,
        exceptionImageAlert,
        exceptionArtAlert,
        exceptionTitleAlert,
        exceptionArtistAlert,
        exceptionShareAlert
    };
}