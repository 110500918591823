import { useState } from 'react';

export const useModal = () => {
    const [openImageDetail, setOpenImageDetail] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleImageDetailClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenImageDetail(true);
      };
    
    return { selectedImage, openImageDetail, setOpenImageDetail,handleImageDetailClick };
}