import React from 'react';
import { Typography } from '@mui/material';

const Logo = ({type}) => {
    return (
        <Typography 
            sx={{ 
                fontFamily: 'MBC1961GulimM', 
                fontSize: '22px',
                color: '#000000',
                p:0,
                m:0,
                ...(type === 'login' && {
                    fontSize: '28px',
                }),
            }}
        >
            아트 프렌즈
        </Typography>
    );
};

export default Logo; 