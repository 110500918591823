import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Button, Tooltip  } from '@mui/material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';  // 음성 설명 아이콘
import PostImageDetail from './PostImageDetail';

const TitleContainer = styled(Box)({
    padding: '4px 0',  // 상하 패딩 축소
    borderBottom: '1px solid #aaaaaa',
    textAlign: 'left'   // 왼쪽 정렬 명시적 설정
  });
  
  const Title = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    marginBottom: '0', // 하단 여백 추가
    '& span': {
      margin: '0 2px',  // 슬래시 좌우 여백 축소
      color: '#666'
    }
  });
  
  function ArtTextComponent({ groupId, title, artist, selectedImage }) {
    if (!title?.trim() || !artist?.trim()) {
        return <></>;
    }

    return (
    <Box sx={{pl:2,pr:2,pt:1,pb:1}}>
        <TitleContainer>
            <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between' // 양쪽 정렬을 위해 추가
            }}>
            <Title variant="h2">
            {title} <span>/</span> {artist}
            </Title>
            <AIDocent selectedImage={selectedImage} groupId={groupId} title={title} artist={artist}/>
            </Box>
        </TitleContainer>
    </Box>
    );
  }
  

  const AIDocent = ({ groupId, selectedImage, title, artist}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false); // 툴팁 상태 추가

    const handleClick = () => {
      setDrawerOpen(true);
      setTooltipOpen(false); // 클릭시 툴팁 닫기
    }

    return (
        <>
            <Tooltip title="AI 해설" open={tooltipOpen}>
                <Button variant="outlined" sx={{
                    borderColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '10px',
                    minWidth: '40px',
                    height: '30px',
                    fontSize: '12px',
                }} onClick={handleClick}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                >
                    <Typography sx={{
                        color: 'rgba(0,0,0,0.5)', 
                        fontSize: '12px', 
                        fontWeight: 600,
                        whiteSpace: 'nowrap'  // 한 줄로 표시
                    }}>AI 해설</Typography>
                </Button>
            </Tooltip>
            {drawerOpen && <PostImageDetail selectedImage={selectedImage} modalOpen={drawerOpen} setModalOpen={setDrawerOpen} isDocent={true} groupId={groupId} title={title} artist={artist}/>}
        </>
    );
  }

  export default ArtTextComponent;
