import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import Logo from '../../common/components/Logo';
import { fetchManualLogin } from '../../repository/user/userRepository';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useDispatch } from 'react-redux';

function ManualLoginPage() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const { onMoveMain } = useCustomNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoginError('');
        
        const result = await fetchManualLogin(dispatch, username, password);
        if (result.success) {
            onMoveMain();
        } else {
            setLoginError(result.error);
        }
    };

    return (
        <Container maxWidth="xs">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
                <Logo />
                <Typography component="h1" variant="h5">
                    로그인
                </Typography>
                <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="사용자 이름"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="비밀번호"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {loginError && (
                        <Typography color="error" sx={{ mt: 1 }}>
                            {loginError}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        로그인
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default ManualLoginPage; 