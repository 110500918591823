import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
  }
});

api.interceptors.request.use(
    config => {
      // '/api/auth'가 포함된 URL이면 Authorization 헤더를 제외
    //   if (!config.url.includes('/api/auth')) {
        // 로컬 스토리지에서 authToken을 가져오기
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
          // Authorization 헤더에 토큰 추가
          config.headers['Authorization'] = `${authToken}`;
        }
    //   }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

// 토큰 갱신을 위한 별도의 axios 인스턴스 생성
const refreshApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        
        // refresh 요청이나 이미 재시도된 요청은 제외
        if (error.response.status === 401 && !originalRequest._isRetry && !originalRequest.url.includes('/api/auth/tokens/refresh')) {
            originalRequest._isRetry = true;
            
            try {
                const res = await refreshApi.post('/api/auth/tokens/refresh', {
                    refresh_token: localStorage.getItem('refreshToken')
                });
                
                if (res.status === 200) {
                    localStorage.setItem('authToken', `Bearer ${res.data.access_token}`);
                    originalRequest.headers['Authorization'] = `Bearer ${res.data.access_token}`;
                    return api(originalRequest);
                }
            } catch (refreshError) {
                // refresh token이 실패하면 로그아웃 처리
                localStorage.removeItem('authToken');
                localStorage.removeItem('refreshToken');
                // 여기서 로그인 페이지로 리다이렉트하거나 다른 처리를 할 수 있습니다
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default api;

