import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, IconButton, Divider } from '@mui/material';
import { formatTime } from '../../util/utils';
import CommentContent from './CommentContent';
import ReplyList from './ReplyList';
import { useDispatch } from 'react-redux';
import { fetchPostCommentLike, fetchDeleteCommentLike } from '../../repository/comments/commentsRepository';
import { icons } from '../../constants/icons';

const CommentCard = ({ index, commentsEndRef, comment, mentionUsers, userId, handleReplyTo, handleLoginAlert }) => {
    const dispatch = useDispatch();

    const [isLiked, setIsLiked] = useState(false);

    useEffect(() => {
        setIsLiked(comment.is_liked);
    }, [comment.is_liked]);

    const handleLike = (commentInfo) => {
        if (!userId) {
            handleLoginAlert();
            return;
        }
        setIsLiked(!isLiked);
        isLiked ? fetchDeleteCommentLike(dispatch, commentInfo.id) : fetchPostCommentLike(dispatch, commentInfo.id);
    };

    return (
        <Box key={`comment-card-${index}`} ref={commentsEndRef} sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
            <Box sx={{ width: '100%', pb: '0px' }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between', // This ensures that the items are spread across the container
                p: '16px 20px',
              }}>
                <Box sx={{ display: 'flex' }}>
                  <Avatar src={comment.user.profile_image_url} sx={{ width: 28, height: 28, marginRight: '8px' }} />
                  <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)', alignSelf: 'flex-start', pr: '6px' }}>
                        {comment.user.username}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.65)', alignSelf: 'center' }}>
                        {formatTime(comment.created_at)}
                      </Typography>
                    </Box>
                    <Box sx={{ pt: '4px' }}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.9)' }}>
                      <CommentContent content={comment.content} mentionUsers={mentionUsers}/>
                      </Typography>
                    </Box>
                    <Box sx={{ pt: '8px', m: 0 }}>
                      <IconButton sx={{ p: 0, mr: '14px' }} onClick={() => handleLike(comment)}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={isLiked ? icons.commentLike.active : icons.commentLike.inactive} alt="Like" style={{ width: 20, height: 20 }} />
                            <Typography sx={{fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', marginLeft: 1}}>
                            {comment.likes_count || ''} {/* 좋아요 수 */}
                            </Typography>
                        </Box>
                      </IconButton>
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => handleReplyTo(comment, "comment")}
                      >
                        <img
                          src={'/assets/icons/comments/comment.png'}
                          alt="comment"
                          style={{ width: 20, height: 20 }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <IconButton sx={{ p: 0, m: 0 }}>
                    <img src={'/assets/icons/comments/more.png'} alt="more" style={{ width: 20, height: 20 }} />
                  </IconButton>
                </Box>
              </Box>
              <Divider variant="middle" />
              {comment.replies && <ReplyList commentId={comment.id} replies={comment.replies} userId={userId} mentionUsers={mentionUsers} handleReplyTo={handleReplyTo} handleLoginAlert={handleLoginAlert}/>}
            </Box>
          </Box>
    );
};

export default CommentCard;