import React,{ useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Avatar, Typography, IconButton, Divider } from '@mui/material';
import { formatTime } from '../../util/utils';
import { icons } from '../../constants/icons';
import { fetchPostReplyLike, fetchDeleteReplyLike } from '../../repository/comments/commentsRepository';
import CommentContent from './CommentContent';

const ReplyCard = ({ index, commentId, reply, mentionUsers, userId, handleReplyTo, handleLoginAlert }) => {
    const dispatch = useDispatch();
    const [isLiked, setIsLiked] = useState(false);


    useEffect(() => {
        setIsLiked(reply.is_liked);
    }, [reply.is_liked]);

    const handleLike = (replyInfo) => {
        if (!userId) {
            handleLoginAlert();
            return;
        }
        setIsLiked(!isLiked);
        console.log(replyInfo);
        isLiked ? fetchDeleteReplyLike(dispatch, commentId, replyInfo.id) : fetchPostReplyLike(dispatch, commentId, replyInfo.id);
    };

    return <Box key={`reply-card-${reply.id}`} sx={{ width: '100%', pb: '0px' }}>
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between', // This ensures that the items are spread across the container
      p: '16px 20px 16px 45px',
    }}>
      <Box sx={{ display: 'flex' }}>
        <Avatar src={reply.user.profile_image_url} sx={{ width: 28, height: 28, marginRight: '8px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)', alignSelf: 'flex-start', pr: '6px' }}>
              {reply.user.username}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.65)', alignSelf: 'center' }}>
              {formatTime(reply.created_at)}
            </Typography>
          </Box>
          <Box sx={{ pt: '4px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.9)' }}>
              <CommentContent content={reply.content} mentionUsers={mentionUsers}/>
            </Typography>
          </Box>
          <Box sx={{ pt: '8px', m: 0 }}>
            <IconButton sx={{ p: 0, mr: '14px' }} onClick={() => handleLike(reply)}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={isLiked ? icons.commentLike.active : icons.commentLike.inactive} alt="Like" style={{ width: 20, height: 20 }} />
                    <Typography sx={{fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', marginLeft: 1}}>
                    {reply.likes_count || ''} {/* 좋아요 수 */}
                    </Typography>
                </Box>            
            </IconButton>
            <IconButton sx={{ p: 0 }} onClick={() => handleReplyTo(reply, "reply")}>
              <img src={'/assets/icons/comments/comment.png'} alt="comment" style={{ width: 20, height: 20 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box>
        <IconButton sx={{ p: 0, m: 0 }}>
          <img src={'/assets/icons/comments/more.png'} alt="more" style={{ width: 20, height: 20 }} />
        </IconButton>
      </Box>
    </Box>
    <Divider variant="middle" />
  </Box>
};

export default ReplyCard;