import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearComments } from '../../../repository/comments/commentsSlice';
import { useDrawer } from '../../../common/hooks/useDrawer';

export const useComment = () => {
    const dispatch = useDispatch();
    // const { setDrawerOpen } = useDrawer();
    const [comment, setComment] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);

    const closeCommentDrawer = () => {
        // 댓글 초기화
        dispatch(clearComments());
        setDrawerOpen(false);
      }
    
    const openCommentDrawer = () => {
        console.log('openCommentDrawer');
        setDrawerOpen(true);
    }
    return { drawerOpen, openCommentDrawer, closeCommentDrawer };
}