import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  TextField,
  Avatar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUpdateProfiles } from '../../repository/profiles/profilesRepository';
import { uploadImage } from '../../api/SwissApi';

const EditProfilePage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.profiles.user);
  const email = useSelector((state) => state.user.email);

  const dispatch = useDispatch();
  const [nickname, setNickname] = useState(user.username || '');
  const [introduction, setIntroduction] = useState(user.introduction || '');
  const [profileImageUrl, setProfileImageUrl] = useState(user.profile_image_url || '');
  const nicknameInputRef = useRef(null);

  useEffect(() => {
    if (nicknameInputRef.current) {
      nicknameInputRef.current.focus();
    }
  }, []);

  const handleComplete = async () => {
    try {
      await fetchUpdateProfiles(dispatch, {
        username: nickname,
        introduction: introduction,
        profile_image_url: profileImageUrl // 이미지 URL 추가

      }).then(() => {
        navigate(-1);
      });
    } catch (error) {
      console.error('프로필 업데이트 실패:', error);
    }
  };

  const handleChangeImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const imageUrl = await uploadImage(file);
      setProfileImageUrl(imageUrl);
    } catch (error) {
      console.error('이미지 업로드 실패:', error);
    }
  };

  const handleDeleteImage = () => {
    setProfileImageUrl("");
  };
  
  return (
    <Box sx={{
      '@media (min-width: 600px)': {
        maxWidth: '600px',
        margin: '0 auto',
      }
    }}>
      <AppBar 
        position="static" 
        color="transparent" 
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton 
            edge="start" 
            onClick={() => navigate(-1)}
          >
            <img 
              src="/assets/icons/back_button.png" 
              alt="뒤로가기" 
            //   style={{ width: '24px', height: '24px' }} 
            />
          </IconButton>
          <Typography 
            onClick={handleComplete}
            sx={{ 
              color: 'rgba(58, 139, 255, 1)',
              fontSize: '15px',
              fontWeight: 600,
              cursor: 'pointer'
            }}
          >
            완료
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          mb: 4
        }}>
          <Box sx={{ position: 'relative' }}>
            <input
              type="file"
              hidden
              id="profile-image-input"
              accept="image/*"
              onChange={handleChangeImage}
            />
            <label htmlFor="profile-image-input">
              <Avatar 
                sx={{ 
                  width: 100, 
                  height: 100,
                  mb: 1,
                  cursor: 'pointer'
                }}
                src={profileImageUrl}
              />
            </label>
            <IconButton 
              component="label"
              htmlFor="profile-image-input"
              sx={{ 
                position: 'absolute',
                right: -10,
                bottom: 0,
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                padding: '4px'
              }}
            >
              <img 
                src="/assets/icons/camera.png" 
                alt="이미지 수정" 
                style={{ width: '20px', height: '20px' }} 
              />
            </IconButton>
          </Box>
          <Typography sx={{ 
            fontSize: '14px',
            mt: 1
              }}
            onClick={handleDeleteImage}
            >
            이미지 삭제
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography sx={{ 
            fontSize: '13px', 
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 1
          }}>
            닉네임
          </Typography>
          <TextField
            fullWidth
            inputRef={nicknameInputRef} // ref 추가
            variant="outlined"
            size="small"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}

            placeholder="닉네임을 입력해주세요"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography sx={{ 
            fontSize: '13px', 
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 1
          }}>
            이메일
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={email}
            readOnly
            disabled
            placeholder="이메일을 입력해주세요"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
        </Box>

        <Box>
          <Typography sx={{ 
            fontSize: '13px', 
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 1
          }}>
            소개
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
            variant="outlined"
            placeholder="나를 소개해주세요!"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EditProfilePage;