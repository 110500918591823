import { getProfiles, getFollowers, postFollower, deleteFollower, putProfiles } from '../../api/SwissApi';
import { setProfile, setFollower, setFollowing, addFollower, removeFollower, updateAddFollower, updateDeleteFollower, updateAddFollowing, updateDeleteFollowing } from './profilesSlice';

export const fetchProfiles = async (dispatch, userId) => {
    try {
        const profiles = await getProfiles(userId);
        
        dispatch(setProfile(profiles));

    } catch (error) {
        const isSecession = error.response?.data?.user?.is_secession;
        
        if (isSecession === true) {
            // 로그인 페이지로 리다이렉션
            window.location.href = '/main';
        }
        console.error('Failed to fetch profiles:', error);    
    }
};

export const fetchUpdateProfiles = async (dispatch, bodyParam) => {
    try {
        const profiles = await putProfiles(bodyParam);
        console.log(profiles);
        // dispatch(setProfile(profiles));
    } catch (error) {
        console.error('Failed to fetch update profiles:', error);
    }
};
export const fetchFollowing = async (dispatch, userId) => {
    try {
        const following = await getFollowers(userId, 'follower');
        dispatch(setFollowing(following));
    } catch (error) {
        console.error('Failed to fetch following:', error);
    }
}

export const fetchFollowers = async (dispatch, userId) => {
    try {
        const followers = await getFollowers(userId);
        dispatch(setFollower(followers));

    } catch (error) {
        console.error('Failed to fetch add follower:', error);
    } 
}

export const fetchAddFollower = async (dispatch, userId) => {
    try {
        const response = await postFollower(userId);
        dispatch(addFollower(response));

    } catch (error) {
        console.error('Failed to fetch add follower:', error);
    } 
}
export const fetchUpdateAddFollowerList = async (dispatch, userId) => {
    try {
        const response = await postFollower(userId);
        dispatch(updateAddFollower(response));
    } catch (error) {
        console.error('Failed to fetch add follower:', error);
    } 
}

export const fetchDeleteFollower = async (dispatch, userId) => {
    try {
        await deleteFollower(userId);
        dispatch(removeFollower(userId));

    } catch (error) {
        console.error('Failed to fetch delete followers:', error);
    } 
}
export const fetchUpdateDeleteFollowerList = async (dispatch, userId) => {
    try {
        await deleteFollower(userId);
        dispatch(updateDeleteFollower(userId));
    } catch (error) {
        console.error('Failed to fetch delete followers:', error);
    } 
}

export const fetchUpdateAddFollowingList = async (dispatch, userId) => {
    try {
        await postFollower(userId);
        dispatch(updateAddFollowing(userId));
    } catch (error) {
        console.error('Failed to fetch add following:', error);
    } 
}

export const fetchUpdateDeleteFollowingList = async (dispatch, userId) => {
    try {
        await deleteFollower(userId);
        dispatch(updateDeleteFollowing(userId));
    } catch (error) {
        console.error('Failed to fetch delete following:', error);
    } 
}