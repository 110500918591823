import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Avatar, TextField, Divider, Typography, Box, IconButton } from '@mui/material';
import Puller from '../../common/components/Puller';
import { icons } from '../../constants/icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetComments, fetchPostComments } from '../../repository/comments/commentsRepository';
import { styled } from '@mui/material/styles';
import CustomSnackbar from '../../common/components/exception/CustomSnackbar';
import { MentionsInput, Mention } from 'react-mentions';
import { fetchMentionUser } from '../../repository/common/commonRepository';
import CommentList from './CommentList';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
const mentionInputStyle = {
  control: {
    backgroundColor: 'rgba(245, 247, 248, 1)',
    fontSize: 14,
    fontWeight: 400,
    minHeight: '44px',
    maxHeight: '88px',
    borderRadius: '12px',
    border: 'none',
    padding: '0',
    boxSizing: 'border-box',
    outline: 'none',
    position: 'relative',
    zIndex: 1,
  },
  input: {
    margin: 0,
    overflow: 'auto',
    height: 'auto',
    padding: '12px 44px 12px 14px',
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    zIndex: 1,  // 입력 필드를 위로
    // position: 'relative',
  },
  highlighter: {
    padding: '12px 44px 12px 14px',
    border: 'none',
    color: '#1173FF',
    // backgroundColor: 'red',
    pointerEvents: 'none', // 추가: 하이라이터를 통과하여 input으로 클릭 이벤트 전달
    zIndex: 2,
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: '14px',
      borderRadius: 8,
      marginTop: 8,
      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
      position: 'absolute',
      bottom: '100%',
      left: 0,
      right: 0,
      zIndex: 1400,
      width: '200px',  // 여기에 원하는 너비 설정
      maxHeight: '200px',      // 약 5개의 항목이 보이도록 높이 설정
      overflowY: 'auto',       // 스크롤 추가
      overflowX: 'hidden',      // 가로 스크롤 제거
      // '&::-webkit-scrollbar': {
      //   width: '6px',               // 스크롤바 너비
      // },
      // '&::-webkit-scrollbar-track': {
      //   background: '#f1f1f1',      // 스크롤바 트랙 배경색
      //   borderRadius: '3px',
      // },
      // '&::-webkit-scrollbar-thumb': {
      //   background: '#888',         // 스크롤바 색상
      //   borderRadius: '3px',
      // },
      // '&::-webkit-scrollbar-thumb:hover': {
      //   background: '#555',         // 스크롤바 호버 시 색상
      // }
    },
    item: {
      padding: '8px 12px',
      alignItems: 'center', 
      '&focused': {
        backgroundColor: 'rgba(43, 9, 255, 0.1)',
      },
    },
  },
};

const CommentDrawer = ({ postGroupId, open, handleClose, setCommentsCount}) => {
  const dispatch = useDispatch();
  
  const {snackbarState, handleLoginAlert, handleCloseSnackbar} = useSnakeBarAlert();
  const [isSend, setIsSend] = useState(false);
  const [commentText, setCommentText] = useState(''); // 댓글 입력 상태
  const [isReplyTo, setIsReplyTo] = useState(0);
  const [isPosting, setIsPosting] = useState(false); // 요청 중 상태
  const [isLiked, setIsLiked] = useState(false);
  const comments = useSelector((state) => state.comments.comments);
  const userId = useSelector((state) => state.user.userId);

  const mentionUsers = useSelector((state) => state.common.mentionUser);
  const commentsEndRef = useRef(null);
  const commentInputRef = useRef(null);

  useEffect(() => {
    if (open) {
      // 히스토리 엔트리 추가
      window.history.pushState(null, '', window.location.pathname);

      // popstate 이벤트 핸들러
      const handlePopState = () => {
        handleCloseDrawer();
      };

      // 이벤트 리스너 등록
      window.addEventListener('popstate', handlePopState);

      // 클린업 함수
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [open]);
  
  useEffect(() => {
    if (open) {
      fetchGetComments(dispatch, postGroupId);

      // 멘션 유저가 없을 경우 멘션 유저 가져오기
      if (mentionUsers.length === 0) {
        fetchMentionUser(dispatch);
      }
    }
  }, [open, postGroupId, dispatch]);


  useEffect(() => {
    setIsSend(commentText.length > 0);
  }, [commentText]);


  const handleCloseDrawer = () => {
    handleClose();
    setCommentText('');
    setIsReplyTo(0);
  };


  const handleInputChange = (event, newValue) => {
    setCommentText(newValue);
  };

  const handleReplyTo = (commentInfo, type="comment") => {
    setCommentText(`@[${commentInfo.user.username}](${commentInfo.user.id}) `)
    
    type == "reply" ?  setIsReplyTo(commentInfo.is_reply_to) : setIsReplyTo(commentInfo.id)
      // TextField에 포커스 설정
    commentInputRef.current.focus();
  }

  const handleSendClick = () => {
    // 아이디가 존재하지않을 경우 예외처리
    if (!userId) {
      handleLoginAlert();
      return;
    }

    if (isSend && !isPosting) {
      setIsPosting(true);

      const bodyParam = isReplyTo > 0 ? {
        is_reply_to: isReplyTo,
        content: commentText,
      } : {
        content: commentText,
      };
      fetchPostComments(dispatch, postGroupId, bodyParam).then(() => {
        // setEditorState(EditorState.push(editorState, ContentState.createFromText('')));
        setCommentsCount(comments.length + 1);
        setCommentText('');
        setIsPosting(false);
        setIsReplyTo(0);
        scrollToBottom();
      }).catch(() => {
        setIsPosting(false); // 실패 시에도 isPosting 상태를 해제합니다.
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (isSend) {
        event.preventDefault();
        handleSendClick();
      }
    }
  };

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleCloseDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 32, // Top left border radius
          borderTopRightRadius: 32, // Top right border radius
          borderColor: '#FFFFFF',
          zIndex: 1300,
          backgroundColor: '#FFFFFF', // Background color of the drawer
          height: 'auto', // Adjust this value as needed to cover the bottom nav
          minHeight: '267px',
          overflow: 'visible'
        },
        '& .MuiDrawer-root': {
          overflow: 'visible'  // Drawer root의 overflow도 visible로
        }
      }}
    >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          overflow: 'visible'  // Box의 overflow도 visible로
        }}>        
        <Puller />
        <Box sx={{ height: 16 }} />
        <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>댓글</Typography>
        </Box>
        {comments.length > 0 ? <Box sx={{ width: '100%', minHeight: '123px', maxHeight: 'calc(100vh - 267px)', overflowY: 'auto', overflowX: 'hidden', mt: 2 }}>
          <CommentList comments={comments} mentionUsers={mentionUsers} userId={userId} commentsEndRef={commentsEndRef} handleReplyTo={handleReplyTo} handleLoginAlert={handleLoginAlert} />
        </Box>
          : <Box sx={{ width: '100%', height: '123px', }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.35)', textAlign: 'center', pt: '50px' }}>
              첫 댓글을 남겨보세요!
            </Typography>
          </Box>
        }
        <Box display="flex" 
        justifyContent="center" 
        sx={{ 
          width: '90%', 
          pt: '20px', 
          pb: '16px',    
          overflow: 'visible',  // 이 Box도 visible로
        }}>
        <Box sx={{ position: 'relative', width: '100%',}}>
        <MentionsInput
            inputRef={commentInputRef}  // 이 부분을 추가
            value={commentText}
            onChange={handleInputChange}
            style={mentionInputStyle}
            placeholder="나의 생각을 댓글로 남겨주세요"
            a11ySuggestionsListLabel="추천 사용자"
            // singleLine={false}  // 여러 줄 입력 허용
            forceSuggestionsAboveCursor={true}
          >
          <Mention
              trigger="@"
              data={mentionUsers}
              displayTransform={(id, display) => `@${display}`}
              renderSuggestion={(suggestion, search, highlightedDisplay) => (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                }}>
                  <Avatar 
                    src={suggestion.profile_image_url} 
                    sx={{ width: 20, height: 20 }}
                  />
                  <Typography sx={{ fontSize: '12px' }}>
                    {highlightedDisplay}
                  </Typography>
                </Box>
              )}
              appendSpaceOnAdd={true}
            />
          </MentionsInput>
          <IconButton
            color="inherit"
            onClick={handleSendClick}
            disabled={!isSend}
            sx={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 3,
              backgroundColor: 'rgba(245, 247, 248, 1)',
            }}
          >
            <img
              src={isSend ? icons.send.active : icons.send.inactive}
              alt="send"
              style={{ width: '24px', height: '24px' }}
            />
          </IconButton>
        </Box>
        </Box>
      </Box>
      <CustomSnackbar
            open={snackbarState.open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={snackbarState.message}
            actionIcon={snackbarState.actionIcon}
            actionRoute={snackbarState.actionRoute}
          />
    </Drawer>
  );
};

export default CommentDrawer;