import { useNavigate } from 'react-router-dom';
import { useSnakeBarAlert } from './useSnakeBarAlert';

export const useCustomNavigate = () => {
    const navigate = useNavigate();


    const onMoveMain = () => {
      navigate('/main');
    }

    const onMoveProfiles = (user) => {
        if(user.is_secession) {
          return;
        }
        navigate(`/profiles/${user.id}`);
      };

    const onMoveEditProfile = (userId) => {
      navigate(`/profiles/${userId}/edit`);
    };

    const onMoveSettingPage = (userId) => {
      navigate(`/profiles/${userId}/setting`);
    };

    const onMoveSearchPlacesMap = (location) => {
        const locationData = encodeURIComponent(JSON.stringify(location));
        navigate(`/search/places/map?data=${locationData}`);
    }


    return {
        onMoveProfiles,
        onMoveSearchPlacesMap,
        onMoveEditProfile,
        onMoveSettingPage,
        onMoveMain,
    }
}