import React,{useState, useEffect} from 'react'
import { Drawer, Box, Card, CardActionArea, Rating, Button, IconButton, Typography } from '@mui/material';
import Puller from './Puller';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ff6d75', // 별이 채워진 색
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47', // 호버 시 별 색
    },
    '& .MuiRating-icon': {
      marginRight: 8, // 별 사이의 간격
    },
    // '& .MuiRating-icon': {
    //     position: 'relative',
    //     marginRight: 8, // 별 사이의 간격
    //     width: '31px',
    //     height: '30px',
    //     overflow: 'hidden', // 별의 일부만 보여주기 위해
    //   },
    //   '& .MuiRating-iconFilled': {
    //     background: 'url("/assets/icons/full_star.png") no-repeat center center', // 완전히 채워진 별 이미지
    //     backgroundSize: 'cover'
    //   },
    //   '& .MuiRating-iconHalf': {
    //     background: 'url("/assets/icons/half_star.png") no-repeat center center', // 반만 채워진 별 이미지
    //     backgroundSize: 'cover'
    //   },
    //   '& .MuiRating-iconEmpty': {
    //     background: 'url("/assets/icons/empty_star.png") no-repeat center center', // 빈 별 이미지
    //     backgroundSize: 'cover'
    //   }
  });
  
  const RatingComponent = ({onChange}) => {
    return (
      <Box
>        <StyledRating
          name="customized-color"
          defaultValue={0}
          getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
          onChange={onChange}
          precision={1}
          icon={<img src="/assets/icons/rating.png" style={{ width: 31, height: 30 }} alt="filled rating icon"/>}
          emptyIcon={<img src="/assets/icons/empty_rating.png" style={{ width: 31, height: 30 }} alt="empty rating icon"/>}
        />
      </Box>
    );
}
const RatingCard = ({name, value, handleChange, handleClose}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    // useEffect(() => {
    //     if (name && value == 0) {
    //         setDrawerOpen(true);
    //     }
    // },[name, value]);

    const handleRatingChange = (event, newValue) => {
        console.log("Selected Rating:", newValue);  // 콘솔에 선택된 별점 출력
        handleChange(newValue);
        setDrawerOpen(false);  // 별점 선택 후 Drawer 닫기
      };
    const handleCloseRating = () => {
        handleChange(0);
        handleClose();
    } 
    const closeDrawer = () => {
        setDrawerOpen(false);
      };

    return <>
        <Card sx={{ 
            boxShadow: 'none',
            p:0,
            margin:0, 
            border: 0,    
            '&:hover': {
            borderRadius: '12px', // Increase border radius on hover
            }
        }}> 
        {value === 0 || value == null ? 
                <CardActionArea onClick={()=>{setDrawerOpen(true)}}>
                <Box sx={{
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                height: '44px', 
                pl: '12px',
                pr: '12px', 
                margin: 0,
                backgroundColor: 'rgba(245, 247, 248, 1)', 
                borderRadius: '12px'
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>  
                    <img src={'/assets/icons/star.png'} alt="category icon" style={{ width: 16, height: 16 }}/>
                    <Box sx={{pl:'10px'}}> 
                    <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.65)' }}>
                        작품 별점 추가 (선택)
                    </Typography>
                    </Box>
                </Box>
                </Box>
                </CardActionArea>
                : 
            <Box sx={{
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                height: '44px', 
                pl: '12px',
                pr: '12px', 
                margin: 0,
                backgroundColor: 'rgba(245, 247, 248, 1)', 
                borderRadius: '12px'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>  
                        <img src={'/assets/icons/star.png'} alt="category icon" style={{ width: 16, height: 16 }}/>
                        <Box sx={{pl:'10px'}}> 
                        <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(255, 67, 101, 1)' }}>
                            {value ? value.toFixed(1) : 'N/A'}
                        </Typography>
                        </Box>
                    </Box>
                    <IconButton edge="start" color="inherit" onClick={()=>{handleCloseRating()}} aria-label="close" sx={{ color: 'black' }}>
                    <img src='/assets/icons/close.png' alt='close' style={{ width: 22, height: 22}}/>
                    </IconButton>
                </Box>
                }
        </Card>
        <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
            '& .MuiDrawer-paper': {
                borderTopLeftRadius: 32, // Top left border radius
                borderTopRightRadius: 32, // Top right border radius
                borderColor: '#FFFFFF',
                backgroundColor: '#FFFFFF', // Background color of the drawer
                height: 'auto', // Adjust this value as needed to cover the bottom nav
                overflow: 'visible'
            }
            }}
        >
       <Box sx={{
        display: 'flex', // flex 컨테이너 설정
        flexDirection: 'column', // 아이템을 수직으로 쌓음
        alignItems: 'center', // 가로축(center)을 기준으로 중앙 정렬
        justifyContent: 'center', // 세로축(center)을 기준으로 중앙 정렬
        height: '311px', // 뷰포트 높이 전체를 사용
        textAlign: 'center' // 텍스트 중앙 정렬
        }}>
            <Puller/>
            <Box sx={{ height: 36 }} />
            <Box>
                <Typography sx={{ fontSize: 18, fontWeight: 700, textAlign: 'center' }}>
                작품은
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: 500, textAlign: 'center'}}>
                어떠셨나요?
                </Typography>
            </Box>
            <Box sx={{ height: 28 }} />
            <RatingComponent onChange={handleRatingChange}/>
            <Box sx={{ height: 52 }} />
            <Button onClick={()=>{setDrawerOpen(false)}}
            sx={{
                width: 160, // 너비를 160px로 설정
                backgroundColor: 'rgba(0, 0, 0, 0.03)', // 배경색 설정
                color: 'rgba(0, 0, 0, 0.55)', // 폰트 색상 설정
                fontSize: '16px', // 폰트 크기 설정
                fontWeight: 600, // 폰트 굵기 설정
                '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)' // 호버 상태의 배경색 변경
                }
            }}>
            건너뛰기
            </Button>
        </Box>
    </Drawer>
   </>
}


export default RatingCard;