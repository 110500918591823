import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography, IconButton, Divider } from '@mui/material';
import CommentContent from './CommentContent';
import { formatTime } from '../../util/utils';
import { icons } from '../../constants/icons';
import ReplyCard from './ReplyCard';
const ReplyList = ({ commentId, replies, mentionUsers, userId, handleReplyTo, handleLoginAlert }) => {
    const [sortedReplies, setSortedReplies] = useState([]);
  
    useEffect(() => {
      // replies가 변경될 때마다 정렬된 배열 업데이트
      const sorted = [...replies].sort((a, b) => 
        new Date(a.created_at) - new Date(b.created_at)
      );
      setSortedReplies(sorted);
    }, [replies]);
  
    return <> {sortedReplies.map((reply, index) => (
        <ReplyCard 
            key={reply.id || index} 
            index={index} 
            commentId={commentId} 
            reply={reply} 
            mentionUsers={mentionUsers} 
            userId={userId} 
            handleReplyTo={handleReplyTo} 
            handleLoginAlert={handleLoginAlert}
        />
    ))} </>
  }

  export default ReplyList;