import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GridPostCard = ({ post }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/posts/${post.id}`);
  };
  return (
    <Box
      sx={{
        position: 'relative',
        paddingTop: '100%', // 1:1 비율 유지
        width: '100%',
        cursor: 'pointer'
      }}
      onClick={handleClick}
    >
      <Box
        component="img"
        src={post.posts[0]?.image_url}
        alt={post.posts[0]?.content}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
    </Box>
  );
};

export default GridPostCard; 