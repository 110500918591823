import { getMentionUser } from '../../api/SwissApi';
import { setMentionUser } from './commonSlice';

export const fetchMentionUser = async (dispatch) => {
    try {
        const mentionUser = await getMentionUser();
        const parsedMentionUser = mentionUser.map((user) => ({
            ...user,
            display: user.username
        }));
        dispatch(setMentionUser(parsedMentionUser));
    } catch (error) {
        console.error('Failed to fetch mention user:', error);
    }
}