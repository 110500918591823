import { getUserInfo } from '../../api/SwissApi';
import { setUser, logout } from './userSlice';
import { postManualLogin } from '../../api/SwissApi';
export const checkLoginStatus = async (dispatch, navigate) => {
    try {
        const response = await getUserInfo();
        const { id :userId, 
            username, 
            profile_image_url: profileImageUrl,
            email,
            introduction
        } = response;

        dispatch(setUser({userId, username, profileImageUrl, email, introduction}));
    } catch (error) {
        console.error('Failed to checkLoginStatus:', error);
        if (navigate) {
            navigate('/login');
        }
    }
}

export const fetchManualLogin = async (dispatch, socialId, password) => {
    const response = await postManualLogin({social_id:socialId, password:password});
    if (response.success) {
        // 헤더에서 Authorization 토큰 추출
        const authToken = response.headers?.get('authorization');
        if (authToken) {
            // 로컬 스토리지에 토큰 저장
            localStorage.setItem('authToken', authToken);
        }
        const { user_id: userId, username, profile_image_url: profileImageUrl, refresh_token: refreshToken } = response.data;
        dispatch(setUser({ userId, username, profileImageUrl }));
        localStorage.setItem('refreshToken', refreshToken);
    }

    return response;
}

export const fetchLogout = async (dispatch) => {
    dispatch(logout());
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
}