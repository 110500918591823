import React from 'react';
import { 
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Container,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLogout } from '../../repository/user/userRepository';
import { deleteUser } from '../../api/SwissApi';
// Material UI styled 사용
const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '12px 0',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: 'none',  // 테두리 제거
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',  // 하단에 1px 테두리 추가
  }));

const SettingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openWithdrawModal, setOpenWithdrawModal] = React.useState(false);
  const [openCompleteModal, setOpenCompleteModal] = React.useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    fetchLogout(dispatch);
  };
  const handleDeleteUser = () => {
    deleteUser().then(() => {
      setOpenCompleteModal(false);
      fetchLogout(dispatch);
      navigate('/');
    });
  };
  const handleWithdraw = () => {
    setOpenWithdrawModal(true);
  };

  const handleWithdrawConfirm = () => {
    setOpenWithdrawModal(false);
    setOpenCompleteModal(true);
  };
  return (
    <Box>
      <StyledAppBar position="static">
      <Toolbar sx={{ justifyContent: 'center' }}>  {/* 중앙 정렬을 위한 스타일 추가 */}
            <IconButton 
                edge="start" 
                color="inherit" 
                aria-label="back" 
                onClick={handleBack}
                sx={{ 
                  color: '#FFFFFF',
                  position: 'absolute',  /* 아이콘을 절대 위치로 설정 */
                  left: 16              /* 왼쪽 여백 설정 */
                }}
              >
                <img 
                  src="/assets/icons/back_button.png" 
                  alt="back_button" 
                  style={{ width: '10px', height: '17px' }} 
                />
              </IconButton>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'rgba(0,0,0,0.85)' }}>
            설정
          </Typography>
        </Toolbar>
      </StyledAppBar>

      <Container maxWidth="sm">
        <List sx={{ mt: 2 }}>
          <StyledListItem onClick={handleLogout}>
            <ListItemText primary="로그아웃" sx={{ fontSize: '14px', fontWeight: 600}}/>
          </StyledListItem>
          <StyledListItem onClick={handleWithdraw}>
            <ListItemText primary="탈퇴하기" sx={{ fontSize: '14px', fontWeight: 600}}/>
          </StyledListItem>
        </List>
      </Container>

      <Dialog
        open={openWithdrawModal}
        onClose={() => setOpenWithdrawModal(false)}
        PaperProps={{
          style: {
            borderRadius: '12px',
            width: '100%',
            maxWidth: '335px',
            padding: '8px'
          }
        }}
      >
        <DialogTitle sx={{ 
          textAlign: 'center', 
          fontSize: '18px', 
          fontWeight: 600,
          pb: 1
        }}>
          정말 탈퇴하시겠습니까?
        </DialogTitle>
        <DialogContent sx={{ 
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '14px',
          pb: 3
        }}>
          탈퇴 후에는 계정을 복구할 수 없어요 😢<br />
          이미 남겨주신 글, 좋아요, 댓글 등은 탈퇴 후에도<br />
          삭제되지 않습니다.
        </DialogContent>
        <DialogActions sx={{ 
          flexDirection: 'column', 
          gap: '8px',
          padding: '0 24px 24px 24px'
        }}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => setOpenWithdrawModal(false)}
            sx={{
              backgroundColor: '#333333',
              color: '#FFFFFF',
              borderRadius: '8px',
              py: 1.5
            }}
          >
            다시 한 번 생각해볼게요
          </Button>
          <Button
            fullWidth
            onClick={handleWithdrawConfirm}
            sx={{
              backgroundColor: '#F7F7F7',
              color: '#000000',
              borderRadius: '8px',
              py: 1.5
            }}
          >
            네, 탈퇴하겠습니다
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCompleteModal}
        PaperProps={{
          style: {
            borderRadius: '12px',
            width: '100%',
            maxWidth: '335px',
            padding: '8px'
          }
        }}
      >
        <DialogTitle sx={{ 
          textAlign: 'center', 
          fontSize: '18px', 
          fontWeight: 600,
          pb: 1
        }}>
          탈퇴가 완료되었습니다.
        </DialogTitle>
        <DialogActions sx={{ 
          justifyContent: 'center',
          padding: '0 24px 24px 24px'
        }}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              handleDeleteUser();
            }}
            sx={{
              backgroundColor: '#333333',
              color: '#FFFFFF',
              borderRadius: '8px',
              py: 1.5
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SettingPage;
