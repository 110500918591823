import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  BottomNavigation, BottomNavigationAction, Drawer, List, ListItem, ListItemText, Typography, Box, Divider
} from '@mui/material';
import { activeBottomNav } from '../../repository/common/commonSlice';
import Puller from './Puller';
import {icons} from '../../constants/icons';

// function BottomNav() {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const value = useSelector((state) => state.common.selectedBottomNavIndex);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const path = location.pathname;
//     switch (path) {
//       case '/main':
//         dispatch(activeBottomNav(0));
//         break;
//       case '/search':
//         dispatch(activeBottomNav(1));
//         break;
//       case '/bookmarks':
//         dispatch(activeBottomNav(3));
//         break;
//       case '/profile':
//         dispatch(activeBottomNav(4));
//         break;
//       default:
//         break;
//     }
//   }, [location, dispatch]);

  // const handleChange = (event, newValue) => {
  //   dispatch(activeBottomNav(newValue));
  //   switch (newValue) {
  //     case 0:
  //       navigate('/main');
  //       break;
  //     case 1:
  //       navigate('/search');
  //       break;
  //     case 2:
  //       setDrawerOpen(true);
  //       break;
  //     case 3:
  //       navigate('/bookmarks');
  //       break;
  //     case 4:
  //       navigate('/profile');
  //       break;
  //     default:
  //       break;
  //   }
  // };

//   const closeDrawer = () => {
//     setDrawerOpen(false);
//   };

//   const handlePostChange = () => {
//     closeDrawer();
//     navigate('/posts/contents/edit');
//   }

//   return (
//     <div>
//       <BottomNavigation
//         value={value}
//         onChange={handleChange}
//         showLabels
//         sx={{
//           position: 'fixed',
//           bottom: 0,
//           left: 0,
//           right: 0,
//           zIndex: 1300,
//           borderTop: '1px solid #dcdcdc',
//           boxShadow: '0 -1px 5px rgba(0,0,0,0.2)'
//         }}
//       >
//         <BottomNavigationAction label="Home" icon={<HomeIcon />} />
//         <BottomNavigationAction label="Search" icon={<SearchIcon />} />
//         <BottomNavigationAction label="Edit" icon={<EditIcon />} />
//         <BottomNavigationAction label="Bookmarks" icon={<BookmarkIcon />} />
//         <BottomNavigationAction label="Profile" icon={<PersonIcon />} />
//       </BottomNavigation>
//       <Drawer anchor="bottom" open={drawerOpen} onClose={closeDrawer}>
//         <List sx={{ marginBottom: '56px' }}>
//           <ListItem button onClick={handlePostChange}>
//             <ListItemIcon>
//               <PhotoCameraIcon />
//             </ListItemIcon>
//             <ListItemText primary="피드 남기기" />
//           </ListItem>
//           <ListItem button onClick={() => { console.log("Write Question clicked"); closeDrawer(); }}>
//             <ListItemIcon>
//               <QuestionAnswerIcon />
//             </ListItemIcon>
//             <ListItemText primary="질문하기" />
//           </ListItem>
//         </List>
//       </Drawer>
//     </div>
//   );
// }

// export default BottomNav;


function BottomNav() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const value = useSelector((state) => state.common.selectedBottomNavIndex);
  const userId = useSelector((state) => state.user.userId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/main':
        dispatch(activeBottomNav(0));
        break;
      case '/search':
        dispatch(activeBottomNav(1));
        break;
      case '/bookmarks':
        dispatch(activeBottomNav(3));
        break;
      case '/profile':
        dispatch(activeBottomNav(4));
        break;
      default:
        break;
    }
  }, [location, dispatch]);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleFeedChange = () => {
    closeDrawer();
    navigate('/posts/contents/edit');
  }
  const handleQuestionChange = () => {
    closeDrawer();
    navigate('/posts/questions/edit');
  }

  const handleChange = (event, newValue) => {
    dispatch(activeBottomNav(newValue));
    switch (newValue) {
      case 0:
        navigate('/main');
        break;
      case 1:
        navigate('/search');
        break;
      case 2:
        // setDrawerOpen(true);
        navigate('/posts/contents/edit');
        break;
      case 3:
        navigate('/bookmarks');
        break;
      case 4:
        userId ? navigate(`/profiles/${userId}`) : navigate('/profiles');
        break;
      default:
        break;
    }
    // Add navigation logic based on newValue
  };

  const renderIcon = (iconType, index) => (
    <img src={value === index ? icons[iconType].active : icons[iconType].inactive} alt={`${iconType} icon`} />
  );

  return (
    <div>
      <BottomNavigation 
        value={value} 
        onChange={handleChange} 
        showLabels
        sx={{ 
          position: 'fixed', 
          bottom: 0, 
          left: 0, 
          right: 0,
          zIndex: drawerOpen ? 1100 : 1200, 
          borderTop: '1px solid #dcdcdc', 
          boxShadow: '0 -4px 12px 0px rgba(0,0,0,0.04)' 
        }}
        >
        <BottomNavigationAction icon={renderIcon('home', 0)} />
        <BottomNavigationAction icon={renderIcon('search', 1)} />
        <BottomNavigationAction icon={renderIcon('edit', 2)} />
        <BottomNavigationAction icon={renderIcon('bookmarks', 3)} />
        <BottomNavigationAction icon={renderIcon('profile', 4)} />
      </BottomNavigation>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 32, // Top left border radius
            borderTopRightRadius: 32, // Top right border radius
            borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF', // Background color of the drawer
            height: 'auto', // Adjust this value as needed to cover the bottom nav
            overflow: 'visible'
          }
        }}
      >
         <Puller/>
         <Box sx={{ height: 16 }} />
         <List sx={{marginBottom:'19px'}}>
           <ListItem button onClick={handleFeedChange} sx={{ height: 71, justifyContent: 'center', display: 'flex' }}>
             <ListItemText primary={<Typography sx={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>피드 남기기</Typography>} />
           </ListItem>
           {/* <Divider component="li" />
           <ListItem button onClick={handleQuestionChange} sx={{ height: 71, justifyContent: 'center', display: 'flex' }}>
             <ListItemText primary={<Typography sx={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>질문하기</Typography>} />
           </ListItem> */}
         </List>
       </Drawer>
    </div>
  );
}

export default BottomNav;