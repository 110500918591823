import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, Box, AppBar, IconButton, Toolbar } from '@mui/material';
import { fetchReadAlert } from '../../repository/alert/alertRepository';
import { useNavigate } from 'react-router-dom';

const AlertPage = () => {
  const alerts = useSelector((state) => state.alert.alert);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleListItemClick = (alert) => {
    const {link_url, is_read, id} = alert;
    navigate(link_url);
    if(!is_read) {
        fetchReadAlert(dispatch, id);
    }
  };
  // created_at 내림차순으로 정렬
  const sortedAlerts = [...alerts].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <Box>
      <List>
        {sortedAlerts.map((alert) => (
          <ListItem
            key={alert.id}
            alignItems="flex-start"
            button
            onClick={() => handleListItemClick(alert)}
            sx={{
              backgroundColor: alert.is_read ? 'white' : '#e0f7fa', // 읽지 않은 알림에 배경색 추가
              borderLeft: alert.is_read ? 'none' : '4px solid #00796b', // 읽지 않은 알림에 왼쪽 테두리
            }}
          >
            <ListItemAvatar>
              <Avatar src={alert.from_user.profile_image_url} />
            </ListItemAvatar>
            <ListItemText
              primary={`${alert.from_user.username} 님이 ${
                alert.type === 'comment' 
                  ? '나의 글에 댓글을' 
                  : alert.type === 'post_like' 
                    ? '나의 글에 좋아요를'
                    : alert.type === 'comment_like'
                    ? '나의 댓글에 좋아요를'
                    : '나를 팔로우했습니다'
              }`}
              secondary={alert.created_at}
              sx={{ color: alert.is_read ? 'text.secondary' : 'text.primary' }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AlertPage;