import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Box, IconButton, Badge, Modal, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { logout } from '../../repository/user/userSlice';
import { fetchAlert } from '../../repository/alert/alertRepository';
import api from '../../api/apiClient';
import AlertPage from '../../features/alert/AlertPage';
import CloseIcon from '@mui/icons-material/Close';
import Logo from './Logo';

function Header() {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateToLogin = () => {
        navigate('/login');
    };

    const handleLogout = () => {
        api.post('/api/auth/logout', {})
            .then(response => {
                console.log('Logged out successfully');
                dispatch(logout());
                navigate('/login');
            })
            .catch(error => {
                console.error('Logout failed', error);
            });
    };

    return (
        <>
            <AppBar position="fixed" sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}>
                <Box
                    sx={{
                        width: '100%',
                        padding: '0',
                        boxSizing: 'border-box',
                        '@media (min-width: 600px)': {
                            maxWidth: '600px',
                            margin: '0 auto',
                        },
                    }}
                >
                    <Toolbar 
                        sx={{ 
                            display: 'flex',
                            justifyContent: 'space-between', 
                            minHeight: '48px', 
                            height: '48px',
                            position: 'relative'
                        }}
                    >
                        <Box sx={{ 
                            display: 'flex',
                            position: 'absolute',
                            bottom: '5px',
                            left:'16px'
                        }}>
                            <Logo />
                        </Box>
                        {user.userId ? (
                            <Box sx={{ ml: 'auto' }}>
                                <NotificationComponent userId={user.userId} />
                            </Box>
                        ) : (
                            <IconButton onClick={navigateToLogin} sx={{ ml: 'auto' }}>
                                <LoginIcon />
                            </IconButton>
                        )}
                    </Toolbar>
                </Box>
            </AppBar>
        </>
    );
}

const NotificationComponent = ({ userId }) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const alert = useSelector((state) => state.alert.alert);
    const alertCount = alert.filter((alert) => !alert.is_read).length;

    useEffect(() => {
        fetchAlert(dispatch);
    }, []);

    const handleNotificationClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <IconButton onClick={handleNotificationClick}>
                <Badge badgeContent={alertCount} color="error" overlap="circular">
                    <img src="assets/icons/bell.png" alt="bell" style={{ width: '28px', height: '28px' }} />
                </Badge>
            </IconButton>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="alert-modal-title"
                aria-describedby="alert-modal-description"
            >
                <Box sx={{
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    bgcolor: 'background.paper',
                    overflowY: 'auto'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, p:1 }}>
                        <IconButton onClick={handleCloseModal} color="inherit">
                            <CloseIcon />
                        </IconButton>
                        <Typography id="alert-modal-title" variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            알림
                        </Typography>
                        <Box sx={{ width: '40px' }} /> {/* CloseIcon과 맞추기 위해 오른쪽에 빈 공간 추가 */}
                    </Box>
                    <AlertPage />
                </Box>
            </Modal>
        </>
    );
};

export default Header;