import { createSlice } from '@reduxjs/toolkit';

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    searchLocations: [],
  },
  reducers: {
    setSearchLocations: (state, action) => {
      if (action.payload.length > 0) {
        state.searchLocations = action.payload;
      }
    },
  },
});

export const { setSearchLocations } = locationSlice.actions;

export default locationSlice.reducer;