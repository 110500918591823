import { getPlacesAutoComplete } from '../../api/SwissApi';
import { setSearchLocations } from './locationSlice';

export const fetchSearchLocations = async (dispatch, keyword) => {
    try {
        const searchLocations = await getPlacesAutoComplete(keyword);
        dispatch(setSearchLocations(searchLocations));
    } catch (error) {
        console.error('Failed to fetch search locations:', error);
    }
}