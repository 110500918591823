import { createSlice } from '@reduxjs/toolkit';

export const commentsSlice = createSlice({
  name: 'comments',
  initialState: {
    is_reply_to: 0,
    comments: [],
  },
  reducers: {
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    addComments: (state, action) => {
      state.comments.push(action.payload);
    },
    addReplies: (state, action) => {
      state.comments.find(comment => comment.id === action.payload.is_reply_to)
      .replies.push(action.payload);
    },
    setIsReply: (state, action) => {
      state.is_reply_to = action.payload;
    },
    addLikeComment: (state, action) => {
      state.comments.find(comment => comment.id === action.payload)
      .likes_count += 1;
    },
    deleteLikeComment: (state, action) => {
      state.comments.find(comment => comment.id === action.payload)
      .likes_count -= 1;
    },
    addLikeReply: (state, action) => {
      state.comments.find(comment => comment.id === action.payload.comment_id)
      .replies.find(reply => reply.id === action.payload.reply_id)
      .likes_count += 1;
    },
    deleteLikeReply: (state, action) => {
      state.comments.find(comment => comment.id === action.payload.comment_id)
      .replies.find(reply => reply.id === action.payload.reply_id)
      .likes_count -= 1;
    },
    
    clearComments: (state, action) => {
      state.comments = [];
    }
  },
});

// Action creators are generated for each case reducer function
export const { setComments, addComments, addReplies, clearComments, setIsReply, addLikeComment, deleteLikeComment, addLikeReply, deleteLikeReply } = commentsSlice.actions;

export default commentsSlice.reducer;