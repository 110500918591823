import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    selectedBottomNavIndex: 0,
    mentionUser: [],
    snackbarAlertState: {
      open: false,
      message: '',
      actionIcon: null,
      actionRoute: '',
    },
    
  },
  reducers: {
    activeBottomNav: (state, action) => {
      state.selectedBottomNavIndex = action.payload;
    },
    setMentionUser: (state, action) => {
      state.mentionUser = action.payload;
    },
    setSnackbarAlertState: (state, action) => {
      state.snackbarAlertState = action.payload;
    },
  },
});

export const { activeBottomNav, setMentionUser, setSnackbarAlertState } = commonSlice.actions;

export default commonSlice.reducer;