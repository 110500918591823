import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'; 
import PostCard from './PostCard';
import CurationSwiper from './CurationSwiper';
import { useSelector, useDispatch } from 'react-redux';
import TabPanel from './TabPanel';
import { fetchMoreFeedList } from '../../repository/posts/postsRepository';
import EmptyPostsMessage from './EmptyPostsMessage';

function MainTabs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const posts = useSelector((state) => state.posts.feeds);
  const next = useSelector((state) => state.posts.feeds_next);

  const [isLoading, setIsLoading] = useState(false);

  const currentTab = new URLSearchParams(location.search).get('tab') || 'home';

  const fetchMorePosts = useCallback(async () => {
    if (isLoading || next === null) return; // Stop if loading or no more pages

    try {
      fetchMoreFeedList(dispatch, next);
    } catch (error) {
      console.error('Failed to load more posts:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, next, dispatch]);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 5 >= document.documentElement.offsetHeight) {
        fetchMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchMorePosts]);

  return (
    <Box>
      <AppBar position="static" color="transparent" elevation={0}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>        
        {/* <Tabs 
          value={currentTab} 
          onChange={(event, newValue) => navigate(`/main?tab=${newValue}`)} 
          aria-label="simple tabs example"
          variant="fullWidth"
          sx={{
            '.MuiTabs-indicator': { // 활성화 시 아래 밑줄
              backgroundColor: 'black', // Sets the indicator (the underline) to black
              height: '2.5px', // Increases the height to make it thicker
            },
            '.MuiTab-root': {
              // color: '#000000', // Sets the text color of all tabs to black
              fontSize: '16px', // Sets the font size of all tabs to 16px
              fontWeight: 600, // Sets the font weight of all tabs to 600
            },
            '.Mui-selected': {  // 선택된 탭에 대한 스타일
              color: 'rgba(0,0,0,0.9)'
            },
            '.MuiTab-root.Mui-disabled': {
              // 비활성화된 탭 스타일
              color: '#c4c4c4', // 비활성화된 탭의 글자 색
              '& .MuiTabs-indicator': {
                backgroundColor: 'gray', // 비활성화된 탭의 밑줄 색
                height: '2px'
              },
            }
          }}
        >
          <Tab label="홈" value="home"/>
          <Tab label="큐레이션" value="curation" disabled />
        </Tabs>
        </Box>
      </AppBar>
      <TabPanel value={currentTab} index="home"> */}
      {/* </TabPanel>
      <TabPanel value={currentTab} index="curation">
        <CurationSwiper/>
      </TabPanel> */}
      </Box>
      </AppBar>
      <Grid container>
        {posts.length === 0 ? (
          <EmptyPostsMessage/>
        ) : (
          posts.map(post => (
            <Grid item xs={12} sm={12} md={12} lg={12} key={post.id}>
              <PostCard post={post} />
            </Grid>
          ))
        )}
        {isLoading && <Typography component="div">Loading more posts...</Typography>}
      </Grid>
    </Box>
  );
}

export default MainTabs;

