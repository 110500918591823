import React from 'react';
import { Box, Typography } from '@mui/material';

const EmptyPostsMessage = ({content="첫 번째 게시글의 주인공이 되어보세요"}) => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        py: 8
      }}
    >
      <Typography variant="h1" sx={{ fontSize: '64px' }}>
        🎨
      </Typography>
      <Typography variant="h6" color="text.secondary">
        아직 게시글이 없네요!
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {content}
      </Typography>
    </Box>
  );
};

export default EmptyPostsMessage;