import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Card, CardActionArea, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditorAtIndex, clearLocationFromEditor, clearRatingFromEditor } from '../../repository/posts/postsSlice';
import { styled } from '@mui/material/styles';
import { Editor, EditorState, Modifier,CompositeDecorator,ContentState } from "draft-js";
import "draft-js/dist/Draft.css";
import RatingCard from '../../common/components/RatingCard';
import LocationSelector from '../../common/components/LocationSelector';
import LocationCard from '../../common/components/LocationCard';
import PostEditor from './PostEditor';
import PostContent from './PostContent';


const PhotoSelector = ({onClick}) =>{
  return (
        <Card sx={{ 
          boxShadow: 'none', 
          border: 0,    
          '&:hover': {
            // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Example hover effect
            borderRadius: '12px', // Increase border radius on hover
          }
          }}> 
          <CardActionArea onClick={onClick}>
            <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width:'90px', height: '90px', padding:0, backgroundColor:'#F2F4F5', borderRadius:'12px' }}>
              <img src='/assets/icons/camera.png' alt={'camera'} style={{ width: 24, height: 24, marginBottom: 4}}/>
              <Typography sx={{ fontSize: '14px', fontWeight:600, color:'rgba(0, 0, 0, 0.65)'}}>
                작품사진(필수)
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
  );
}

const PostEditForm = ({ handlePhotoEdit }) => {
  const dispatch = useDispatch();
  const locationValue = useSelector((state) => state.posts.editors[0].location);
  const RatingValue = useSelector((state) => state.posts.editors[0].rating);
  const contentValue = useSelector((state) => state.posts.editors[0].content);
  const navigate = useNavigate();

  return (
    <Box sx={{
      width: '100%',
      maxWidth: '800px',
      mx: 'auto',
      border:0,
      boxSizing: 'border-box',
      '@media (min-width: 800px)': {
        margin: '0 auto',
      }
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '12px' }}>
        <PhotoSelector onClick={()=>{handlePhotoEdit('init')}}/>
        {!locationValue?.name && <LocationSelector type='foursquare' contentIndex={0}/>}
      </Box>

      <Box sx={{marginBottom: '12px'}}/>
      {locationValue?.name && 
        <Box>
          <LocationCard location={locationValue} handleClose={()=>{dispatch(clearLocationFromEditor({index:0}))}} />
          <Box sx={{marginBottom: '12px'}}/>
        </Box>
      }
        {/* <RatingCard 
            placeName={locationValue?.name} 
            value={RatingValue}
            handleChange={(newValue)=>dispatch(updateEditorAtIndex({index:0, rating:newValue}))}
            handleClose={()=>dispatch(clearRatingFromEditor({index:0}))}
        /> */}
        <Box sx={{marginBottom: '10px'}}/>
        <PostContent content={contentValue} handleChange={(value)=>(dispatch(updateEditorAtIndex({index:0, content:value})))}/>
      {/* <PostEditor contentIndex={0} content={contentValue} handleChange={(value)=>(dispatch(updateEditorAtIndex({index:0, content:value})))}/> */}
    </Box>
  );
};

export default PostEditForm;