import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

function PlaceListItem({ place, onLocationAdd, onMapOpen }) {
  return (
    <ListItem button key={place.place_id} onClick={() => onLocationAdd(place.place_id)}>
      <ListItemText 
        primary={
          <React.Fragment>
            <Typography component="span" sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(38, 38, 38, 1)' }}>
              {place.structured_formatting.main_text}
            </Typography>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <img 
              src="/assets/icons/location-14.png" 
              alt="location" 
              style={{ verticalAlign: 'middle', width: '14px', height: '14px', marginRight: '1px' }} 
            />
            <Typography component="span" sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0, 0, 0, 0.55)' }}>
              {place.structured_formatting.secondary_text}
            </Typography>
          </React.Fragment>
        }
      />
      <ListItemIcon>
        <IconButton onClick={(event) => onMapOpen(event, place)} edge="end" aria-label="map">
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.55)' }}>
            지도
          </Typography>
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
}

export default PlaceListItem;