import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Drawer, Typography, Button, Avatar, Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, ListItemButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProfiles, fetchFollowers, fetchFollowing, fetchAddFollower, fetchDeleteFollower, fetchUpdateAddFollowerList, fetchUpdateDeleteFollowerList, fetchUpdateAddFollowingList, fetchUpdateDeleteFollowingList } from '../../repository/profiles/profilesRepository';
import PostCard from '../../common/components/PostCard';
import BottomNav from '../../common/components/BottomNav';
import Puller from '../../common/components/Puller';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import GridPostCard from '../../common/components/GridPostCard';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import CustomSnackbar from '../../common/components/exception/CustomSnackbar';
import EmptyPostsMessage from '../../common/components/EmptyPostsMessage';
import FollowButton from './FollowButton';

const ProfilePage = () => {
  return <Box>
    <ProfileContent/>
    <Toolbar/>
    <BottomNav/>
  </Box>
}

function ProfileContent() {
    const dispatch = useDispatch();
    const { onMoveEditProfile, onMoveSettingPage, onMoveMain } = useCustomNavigate();
    const { handleLoginAlert, handleCloseSnackbar, snackbarState } = useSnakeBarAlert();
    const { user_id } = useParams();

    const user = useSelector((state) => state.profiles.user);
    const posts = useSelector((state) => state.profiles.posts);
    const follower_count = useSelector((state) => state.profiles.follower_count);
    const following_count = useSelector((state) => state.profiles.following_count);
    const followers = useSelector((state) => state.profiles.followers);
    const loginUserId = useSelector((state) => state.user.userId);
    const is_follow = useSelector((state) => state.profiles.is_follow);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [followingDrawerOpen, setFollowingDrawerOpen] = useState(false);
    const [viewMode, setViewMode] = useState('list');

    useEffect(() => {
      window.scrollTo(0, 0); // 스크롤을 맨 위로 초기화
      if (user_id) {
        fetchProfiles(dispatch, user_id);
        // 팔로워 데이터를 fetch하는 함수 (예시)
        fetchFollowers(dispatch, user_id);
      }
    }, [user_id, dispatch]);
    
    const handleFollower = (is_follow, userId) => {
      if(!loginUserId) {
        handleLoginAlert();
        return;
      }      
      is_follow ? fetchDeleteFollower(dispatch, userId) : fetchAddFollower(dispatch, userId);
    }

    const handleUpdateFollowerList = (is_follow, userId) => {
      if(!loginUserId) {
        handleLoginAlert();
        return;
      }      
      is_follow ? fetchUpdateDeleteFollowerList(dispatch, userId) : fetchUpdateAddFollowerList(dispatch, userId);
    }

    const handleUpdateFollowingList = (is_follow, userId) => {
      if(!loginUserId) {
        handleLoginAlert();
        return;
      }      
      is_follow ? fetchUpdateDeleteFollowingList(dispatch, userId) : fetchUpdateAddFollowingList(dispatch, userId);
    }

    const handleFollowerClick = () => {
      fetchFollowers(dispatch, user_id);
      setDrawerOpen(true);
    };
  
    const handleCloseFollowerModal = () => {
      setDrawerOpen(false);
    };
  
    const handleFollowingClick = () => {
      fetchFollowing(dispatch, user_id);
      setFollowingDrawerOpen(true);
    };

    const handleCloseFollowingModal = () => {
      setFollowingDrawerOpen(false);
    };

    const handleClose = () => {
      onMoveMain();
    };
  
    return (
      <Box sx={{
        '@media (min-width: 600px)': {
          maxWidth: '600px',
          padding: '0',
          margin: '0 auto',
        }
      }}>
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '194px',
          backgroundImage: 'url(/assets/images/bg_test.jpeg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1
        }} />
        <Box sx={{ p: '0 16px', top: 0 }}>
        <AppBar position="relative" color="transparent" sx={{
            elevation: 0,
            p: 1,
            margin: 0,
            boxShadow: 'none',
            border: 0,
            zIndex: 10,
            // background: 'linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)'
          }}>
            <Toolbar sx={{ justifyContent: 'space-between', p: 0, margin: 0 }}>
              <IconButton 
                edge="start" 
                color="inherit" 
                aria-label="back" 
                onClick={handleClose}
                sx={{ color: '#FFFFFF' }}
              >
                <img 
                  src="/assets/icons/back_button.png" 
                  alt="back_button" 
                  style={{ width: '10px', height: '17px' }} 
                />
              </IconButton>
              {user.id === loginUserId &&
                <IconButton sx={{ p: 0, m: 0 }} onClick={()=>{onMoveSettingPage(loginUserId);}}>
                  <img src={'/assets/icons/more_icon.png'} alt="more" style={{ width: 20, height: 20 }} />
                </IconButton>
              }
            </Toolbar>
          </AppBar>
          <Box sx={{ height: '57px' }} />
          <Avatar sx={{ width: 64, height: 64, marginBottom: '12px', border: 1.5, borderColor: 'rgba(255,255,255,1)' }} src={user.profile_image_url} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'rgba(0,0,0,1)' }}>{user.username}</Typography>
              <Box sx={{ display: 'flex',fontSize: '14px', fontWeight: 500, color: 'rgba(115,115,115,1)',}}>
                <Typography onClick={handleFollowerClick} sx={{cursor: 'pointer' }}>
                  팔로워 {follower_count}
                </Typography>
                {loginUserId == user_id &&
                  <>
                    <Typography sx={{ margin: '0 4px' }}>|</Typography>
                    <Typography sx={{ cursor: 'pointer' }} onClick={handleFollowingClick}>
                      팔로잉 {following_count}
                    </Typography>
                  </>
                }
              </Box>

              <Box sx={{ height: '10px' }} />
              <Typography sx={{ 
                fontSize: '12px', 
                fontWeight: 500,
                whiteSpace: 'pre-line'
              }}>
                {user.introduction}
              </Typography>
            </Box>
            {user.id === loginUserId ? (
              <Button 
                variant="outlined" 
                onClick={()=>{onMoveEditProfile(loginUserId)}}
                sx={{
                  color: 'rgba(0,0,0,0.85)',
                  borderColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '20px',
                  width: '100px',
                  height: '36px',
                  fontSize: '14px',
                  fontWeight: '500',
                  whiteSpace: 'nowrap'
                }}
              >
                프로필 수정
              </Button>
            ) : (
              <FollowButton onClick={()=> handleFollower(is_follow, user_id)} is_follow={is_follow}/>
            )}
          </Box>
  
          {/* 팔로워 모달 */}
          <DrawerFollowList followers={followers} loginUserId={loginUserId} drawerOpen={drawerOpen} closeDrawer={handleCloseFollowerModal} handleFollower={handleUpdateFollowerList}/>
          <DrawerFollowingList drawerOpen={followingDrawerOpen} closeDrawer={handleCloseFollowingModal} handleFollowing={handleUpdateFollowingList}/>
          <Box>
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgba(0,0,0,0.75)' }}>
              {/* 소개글 */}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ 
          borderBottom: 1, 
          borderColor: 'divider', 
          backgroundColor: 'rgba(255, 255, 255, 1)',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0 16px'
        }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton 
              onClick={() => setViewMode('list')}
              sx={{ 
                color: viewMode === 'list' ? 'primary.main' : 'text.secondary',
                p: '4px'
              }}
            >
              <ViewListIcon />
            </IconButton>
            <IconButton 
              onClick={() => setViewMode('grid')}
              sx={{ 
                color: viewMode === 'grid' ? 'primary.main' : 'text.secondary',
                p: '4px'
              }}
            >
              <GridViewIcon />
            </IconButton>
          </Box>
        </Box>

        <Box>
          <Grid container spacing={viewMode === 'grid' ? 1 : 0}>
            {posts && posts.length > 0 ? (
              posts.map((post) => (
                <Grid item xs={viewMode === 'grid' ? 4 : 12} key={post.id}>
                  {viewMode === 'grid' ? (
                    <GridPostCard post={post} />
                  ) : (
                    <PostCard post={post}/>
                  )}
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <EmptyPostsMessage content={"여기에 당신의 이야기를 채워주세요"}/>
              </Grid>
            )}
          </Grid>
        </Box>
        <CustomSnackbar
            open={snackbarState.open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={snackbarState.message}
            actionIcon={snackbarState.actionIcon}
            actionRoute={snackbarState.actionRoute}
          />
        <Toolbar />
      </Box>
    );
  }
  


const DrawerFollowList = ({followers, loginUserId, drawerOpen, closeDrawer, handleFollower}) => {
  const {onMoveProfiles} =useCustomNavigate();

  useEffect(() => {
    if (drawerOpen) {
      // 히스토리 엔트리 추가

      window.history.pushState(null, '', window.location.pathname);

      // popstate 이벤트 핸들러
      const handlePopState = () => {
        closeDrawer();
      };

      // 이벤트 리스너 등록
      window.addEventListener('popstate', handlePopState);

      // 클린업 함수
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [drawerOpen]);

  return <>
  <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 32, // Top left border radius
            borderTopRightRadius: 32, // Top right border radius
            borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF', // Background color of the drawer
            height: 'auto', // Adjust this value as needed to cover the bottom nav
            overflow: 'visible'
          }
        }}
      >
         <Puller/>
         <Box sx={{ height: 16 }} />
         <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>팔로워</Typography>
        </Box>
         <List sx={{marginBottom:'19px'}}>
            {followers.map((follower) => (
                  <ListItemButton 
                    key={follower.id} 
                    disableRipple={false}
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      cursor: 'pointer',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                      },
                      transition: 'background-color 0.3s'
                     }}
                    onClick={()=> {
                      closeDrawer();
                      onMoveProfiles(follower.user);
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                      <ListItemAvatar>
                        <Avatar src={follower.user.profile_image_url} />
                      </ListItemAvatar>
                      <ListItemText primary={follower.user.username} />
                    </Box>
                    {loginUserId === follower.user.id ? 
                      null
                      : <Box onClick={(e) => e.stopPropagation()}>
                        <FollowButton 
                          onClick={() => handleFollower(follower.is_follow, follower.user.id)} 
                          is_follow={follower.is_follow}
                        />
                      </Box>
                    }
                </ListItemButton>
              ))}
         </List>
       </Drawer>
  </>;
}

const DrawerFollowingList = ({drawerOpen, closeDrawer, handleFollowing}) => {
  const {onMoveProfiles} =useCustomNavigate();
  const followings = useSelector((state) => state.profiles.followings);

  useEffect(() => {
    if (drawerOpen) {
      // 히스토리 엔트리 추가

      window.history.pushState(null, '', window.location.pathname);

      // popstate 이벤트 핸들러
      const handlePopState = () => {
        closeDrawer();
      };

      // 이벤트 리스너 등록
      window.addEventListener('popstate', handlePopState);

      // 클린업 함수
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [drawerOpen]);

  return <>
  <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 32, // Top left border radius
            borderTopRightRadius: 32, // Top right border radius
            borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF', // Background color of the drawer
            height: 'auto', // Adjust this value as needed to cover the bottom nav
            overflow: 'visible'
          }
        }}
      >
         <Puller/>
         <Box sx={{ height: 16 }} />
         <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>팔로잉</Typography>
        </Box>
         <List sx={{marginBottom:'19px'}}>
            {followings.map((following) => (
                  <ListItemButton 
                    key={following.id} 
                    disableRipple={false}
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      cursor: 'pointer',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                      },
                      transition: 'background-color 0.3s'
                     }}
                    onClick={()=> {
                      closeDrawer();
                      onMoveProfiles(following.user);
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                      <ListItemAvatar>
                        <Avatar src={following.user.profile_image_url} />
                      </ListItemAvatar>
                      <ListItemText primary={following.user.username} />
                    </Box>
                    <Box onClick={(e) => e.stopPropagation()}>
                      <FollowButton 
                        onClick={() => handleFollowing(following.is_follow, following.user.id)} 
                        is_follow={following.is_follow}
                    />
                    </Box>
                  </ListItemButton>
                ))}
         </List>
       </Drawer>
  </>;
}

export default ProfilePage;
