import React,{useEffect, useState} from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Box, Typography, Avatar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMentionUser } from '../../repository/common/commonRepository';

const mentionInputStyle = {
  control: {
    fontSize: 16,
    fontWeight: 400,
    minHeight: '50px',
    borderRadius: '12px',
    border: 'none',
    padding: '0',
    boxSizing: 'border-box',
    outline: 'none',
    position: 'relative',
    zIndex: 1,
  },
  input: {
    margin: 0,
    overflow: 'auto',
    height: 'auto',
    padding: '0',
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    zIndex: 1,  // 입력 필드를 위로
  },
  highlighter: {
    padding: '0',
    border: 'none',
    color: '#1173FF',
    // backgroundColor: 'red',
    pointerEvents: 'none', // 추가: 하이라이터를 통과하여 input으로 클릭 이벤트 전달
    zIndex: 2,
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: '14px',
      borderRadius: 8,
      marginTop: 8,
      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
      position: 'absolute',
      bottom: '100%',
      left: 0,
      right: 0,
      zIndex: 1400,
      width: '200px',  // 여기에 원하는 너비 설정
      maxHeight: '200px',      // 약 5개의 항목이 보이도록 높이 설정
      overflowY: 'auto',       // 스크롤 추가
      overflowX: 'hidden',      // 가로 스크롤 제거
    },
    item: {
      padding: '8px 12px',
      alignItems: 'center', 
      '&focused': {
        backgroundColor: 'rgba(43, 9, 255, 0.1)',
      },
    },
  },
};

const hashTags = [
  // 전시 유형
  { id: 'solo_exhibition', display: '개인전' },
  { id: 'group_exhibition', display: '단체전' },
  { id: 'special_exhibition', display: '특별전' },
  { id: 'permanent_exhibition', display: '상설전' },
  { id: 'international_exhibition', display: '국제전' },
  
  // 전시 공간
  { id: 'museum', display: '미술관' },
  { id: 'gallery', display: '갤러리' },
  { id: 'artfair', display: '아트페어' },
  { id: 'alternative_space', display: '대안공간' },
  { id: 'popup', display: '팝업전시' },
  
  // 관람 경험
  { id: 'must_see', display: '강추전시' },
  { id: 'exhibition_review', display: '전시리뷰' },
  { id: 'curator_talk', display: '큐레이터토크' },
  { id: 'artist_talk', display: '작가와의대화' },
  { id: 'guided_tour', display: '도슨트투어' },
  
  // 전시 특성
  { id: 'media_art', display: '미디어아트' },
  { id: 'interactive', display: '인터랙티브' },
  { id: 'immersive', display: '몰입형전시' },
  { id: 'retrospective', display: '회고전' },
  { id: 'emerging_artist', display: '신진작가' },
  
  // 부가 정보
  { id: 'free_admission', display: '무료전시' },
  { id: 'last_week', display: '막바지전시' },
  { id: 'opening', display: '오프닝' },
  { id: 'finissage', display: '피니사주' }
];
  
const PostContent = ({ content, handleChange, readOnly }) => {
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false);
    const mentionUsers = useSelector((state) => state.common.mentionUser);

    useEffect(() => {
      if (mentionUsers.length === 0) {
        fetchMentionUser(dispatch);
      }
    }, [mentionUsers])

    const handleFocus = () => {
        setIsFocused(true);
      };
      
    const handleBlur = () => {
        setIsFocused(false);
    };

    const focusEditor = () => {
        // editor.current.focus();
    };

    const handleInputChange = (newValue) => {
      const lastChar = newValue.slice(-1);
      
      // 스페이스바가 눌렸고, #으로 시작하는 단어가 있는지 확인
      if (lastChar === ' ') {
          const words = newValue.split(' ');
          const lastWord = words[words.length - 2]; // 스페이스 이전 단어
          
          if (lastWord && lastWord.startsWith('#')) {
              const tagText = lastWord.slice(1); // # 제거
              if (tagText) {
                  // 태그 형식으로 변환
                  words[words.length - 2] = `#[${tagText}](${tagText})`;
                  const updatedContent = words.join(' ');
                  handleChange(updatedContent);
                  return;
              }
          }
      }
      
      handleChange(newValue);
    };

    return (
        <Box>
            <Box sx={{position: 'relative', padding:1,   
            border: isFocused ? "0px" : "0px solid transparent", // Change color when focused
            minHeight:'50px', cursor: "text"}} onClick={focusEditor}>
            <CustomPlaceholder text={content} isFocused={isFocused}/>
            <MentionsInput
                readOnly={readOnly}
                value={content}
                style={mentionInputStyle}
                onChange={(e) => handleInputChange(e.target.value)}
                singleLine={false}
                onFocus={handleFocus}
                onBlur={handleBlur}
            >

            <Mention
            trigger="@"
            data={mentionUsers}
            displayTransform={(id, display) => `@${display}`}
            markup="@[__display__](__id__)"
            renderSuggestion={(suggestion, search, highlightedDisplay) => (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
              }}>
                <Avatar 
                  src={suggestion.profile_image_url} 
                  sx={{ width: 20, height: 20 }}
                />
                <Typography sx={{ fontSize: '12px' }}>
                  {highlightedDisplay}
                </Typography>
              </Box>
            )}
            appendSpaceOnAdd={true}
            />
            <Mention
            trigger="#"
            data={[]}
            displayTransform={(id, display) => `#${display}`}
            markup="#[__display__](__id__)"
            appendSpaceOnAdd={true}
            />
        </MentionsInput>
      </Box>
    </Box>
    );
  };

  const CustomPlaceholder = ({type, text, isFocused}) => {
    if (text?.length > 0 || isFocused) {
        return null;
    }
  
    return (
      <Box position="absolute" top={1} left={10} pointerEvents="none" style={{ width: '100%', opacity: 0.55 }}>
          <>
            <Typography sx={{fontWeight:500, fontSize:'16px', textAlign: 'left'}}>
              작품에 대한 생각을 공유해 주세요!
            </Typography>
            <Typography sx={{fontWeight:500, fontSize:'16px', textAlign: 'left'}}>
              <span style={{ color: '#1173FF' }}>#태그</span>를 추가할 수도 있어요.
            </Typography>
          </>
      </Box>
    );
  };

  export default PostContent;