import api from './apiClient';
import noAuthApi from './noauthApiClient';
import axios from 'axios';

export const postComments = async (postGroupId, bodyParam) => {
    const response = await api.post(`/api/posts/${postGroupId}/comments`, bodyParam);
    return response.data;
};

export const getComments = async (postGroupId) => {
    const response = await api.get(`/api/posts/${postGroupId}/comments`);
    return response.data;
  };

export const postFeed = async (feed) => {
    const response = await api.post(`/api/posts`, feed);
    return response.data;
};

export const getFeedList = async (type='feed') => {
    const response = await api.get(`/api/posts?type=${type}`);
    return response.data;
};

export const getMoreFeedList = async (nextUrl) => {
    const response = await api.get(`${nextUrl}`);
    return response.data;
}

export const getPostDetail = async (postGroupId) => {
    const response = await api.get(`/api/posts/${postGroupId}`)
    return response.data;
}

export const getPostPopular = async () => {
    const response = await api.get(`/api/posts/popular`);
    return response.data;
}
     
export const getBookmarks = async (type) => {
    const response = await api.get(`/api/bookmarks?type=${type}`);
    return response.data;
}; 

export const postBookmarks = async (type, targeId) => {
    const response = await api.post(`/api/bookmarks`, { target_id: targeId, type: type });
    return response.data;
}

export const deleteBookmarks = async (type, targetId) => {
    const response = await api.delete(`/api/bookmarks/${targetId}?type=${type}`);
    return response.data;
}

export const getFollowers = async (userId, type = 'followed') => {
    const response = await api.get(`/api/followers/${userId}?type=${type}`);
    return response.data;
}

export const postFollower = async (userId) => {
    const response = await api.post(`/api/followers`, { followed_id : userId});
    return response.data;
}

export const deleteFollower = async (userId) => {
    const response = await api.delete(`/api/followers?followed_id=${userId}`);
    return response.data;
}

export const getProfiles = async (userId) => {
    const response = await api.get(`/api/auth/profiles/${userId}`);
    return response.data;
}

export const putProfiles = async (bodyParam) => {
    const response = await api.put(`/api/auth/user-info`, bodyParam);
    return response.data;
}

export const getSignedUrl = async (fileName) => {
    const response = await api.get(`/api/posts/images/signed-url?file_name=${encodeURIComponent(fileName)}`);
    return response.data;
};

export const getPublicUrl = async (fileName) => {
    const response = await api.get(`/api/posts/images/public-url?file_name=${encodeURIComponent(fileName)}`);
    return response.data;
};

export const postGemini = async (imageUrl) => {
    const response = await api.post(`/api/gemini/generate`, {'image_url':imageUrl});
    return response.data;
}

export const getSearchKeyword = async (keyword) => {
    const response = await api.get(`/api/search?keyword=${keyword}`);
    return response.data;
}

export const getKeywordRanking = async () => {
    const response = await api.get(`/api/search/ranking`);
    return response.data;
}

export const getAlert = async () => {
    const response = await api.get(`/api/notify`);
    return response.data;
}

export const readAlert = async (alertId) => {
    const response = await api.post(`/api/notify/${alertId}/read`);
    return response.data;
}

export const getUserInfo = async () => {
    const response = await api.get(`/api/auth/user-info`);
    return response.data;
}

export const getMentionUser = async () => {
    const response = await api.get(`/api/search/mentionable-users`);
    return response.data;
}

export const uploadImage = async (image) => {
    const timestamp = new Date().getTime();
    const uniqueFileName = `${timestamp}_${image.name}`;

    // 1. GCS 이미지 업로드할 권한 요청 - api response : upload_url
    const uploadResponse = await getSignedUrl(uniqueFileName);
    const uploadUrl = uploadResponse.upload_url;

    // 2. 이미지 업로드 - gcs
    await axios.put(uploadUrl, image, {
        headers: { 'Content-Type': 'application/octet-stream' }
    });

    // 3. 업로드한 이미지를 접근할 수 있는 url 요청 - api
    const publicResponse = await getPublicUrl(uniqueFileName);
    const publicUrl = publicResponse.public_url;

    return publicUrl;
  };

export const postFeedLike = async (postId) => {
    const response = await api.post(`/api/posts/${postId}/likes`);
    return response.data;
}

export const deleteFeedLike = async (postId) => {
    const response = await api.delete(`/api/posts/${postId}/likes`);
    return response.data;
}

export const postCommentLike = async (commentId) => {
    const response = await api.post(`/api/comments/${commentId}/likes`);
    return response.data;
}

export const deleteCommentLike = async (commentId) => {
    const response = await api.delete(`/api/comments/${commentId}/likes`);
    return response.data;
}

export const getPlacesAutoComplete = async (keyword) => {
    const encodedKeyword = encodeURIComponent(keyword);

    const response = await api.get(`/api/search/places/autocomplete?keyword=${encodedKeyword}`);
    return response.data;
}

export const getGooglePlacesAutoComplete = async (keyword) => {
    const response = await api.get('/api/search/google/autocomplete', {
        params: { input: keyword },
    });
    return response.data;
}

export const postDocent = async (params) => {
    const response = await api.post(`/api/gemini/generate-answer`, params);
    return response.data;
}

export const deleteUser = async () => {
    const response = await api.delete(`/api/auth/secession`);
    return response.data;
}

export const postManualLogin = async (params) => {
    try {
        const response = await noAuthApi.post(`/api/auth/manual-login`, params);
        return { success: true, data: response.data };
    } catch (error) {
        if (error.response?.status === 401) {
            return { success: false, error: '아이디 또는 비밀번호가 잘못되었습니다' };
        }
        return { success: false, error: '로그인 중 오류가 발생했습니다' };
    }
}