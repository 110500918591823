import { CompositeDecorator, EditorState, ContentState, SelectionState, Modifier  } from 'draft-js';
import { Box, Typography } from '@mui/material';

const ANNOTATION_REGEX = /@\[[\w\uac00-\ud7a3\s]+\]\([\w\uac00-\ud7a3]+\)/g;
const HASHTAG_REGEX = /#\[[\w\uac00-\ud7a3\s]+\]\([\w\uac00-\ud7a3]+\)/g;

const RatingSpan = ({ entityKey, contentState, children }) => {
    if (!entityKey || !contentState.getEntity(entityKey)) {
        console.error("Invalid entity or entity key not found.");
        return <span>{children}</span>;
    }
    const entity = contentState.getEntity(entityKey);
    const { rating } = entity.getData();
    const parseRating = parseInt(rating);

    return <span style={{ display: 'inline'}}>
    {parseRating > 0 && 
        <span style={{marginRight:'8px',}}>
            <img src={'/assets/icons/star.png'} alt="category icon" style={{ display: 'inline', width: 14, height: 14, marginRight:'3px',verticalAlign:'-2px'}}/>
            <span style={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0, 0, 0, 1)', verticalAlign:'baseline'}}>
                {parseRating && parseRating > 0 ? parseRating.toFixed(1) : '-'}
            </span>
        </span>
    }
    <span style={{fontSize: '15px', fontWeight: 400, lineHeight:'22px', color: 'rgba(0, 0, 0, 0.9)'}}>
        {children}
    </span>
    </span>
};

const AnnotationSpan = ({ children }) => {
    return (
        <span style={{ color: '#1173FF', fontWeight:500, fontSize:'15px' }}>
            {children}
        </span>
    );
};

const HashtagSpan = (props) => {
    return <span style={{ color: '#1173FF', fontWeight:500, fontSize:'15px' }}>{props.children}</span>;
};

const ratingStrategy = (contentBlock, callback, contentState) => {
    if (contentBlock.getKey() === contentState.getFirstBlock().getKey()) {
        callback(0, 1);  // Apply the RatingSpan at the start of the first block
    }
};

function annotationStrategy(contentBlock, callback, contentState) {
  findWithRegex(ANNOTATION_REGEX, contentBlock, callback);
}

function hashtagStrategy(contentBlock, callback, contentState) {
  findWithRegex(HASHTAG_REGEX, contentBlock, callback);
}


function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const compositeDecorator = new CompositeDecorator([
    {
      strategy: hashtagStrategy,
      component: HashtagSpan,
    },
    {
        strategy: annotationStrategy,
        component: AnnotationSpan, // Add this line to incorporate the annotation handling
    },
    {
        strategy: ratingStrategy,
        component: props => <RatingSpan {...props} rating={props.contentState.getEntity(props.entityKey).getData().rating} />
    },
  ]);

export const NoRatingDecorator = new CompositeDecorator([
    {
      strategy: hashtagStrategy,
      component: HashtagSpan,
    },
    {
        strategy: annotationStrategy,
        component: AnnotationSpan, // Add this line to incorporate the annotation handling
    },
  ]);

export const createContentWithDecorator = (content, rating) => {
    let contentState = ContentState.createFromText(content);
    contentState = contentState.createEntity('RATING', 'IMMUTABLE', {rating});
    const entityKey = contentState.getLastCreatedEntityKey();

    const selectionState = SelectionState.createEmpty(contentState.getFirstBlock().getKey()).merge({
        anchorOffset: 0,
        focusOffset: contentState.getFirstBlock().getText().length
    });

    contentState = Modifier.applyEntity(contentState, selectionState, entityKey);

    return EditorState.createWithContent(contentState, compositeDecorator);
};

export const CustomPlaceholder = ({type, editorState, isFocused}) => {
    const text = editorState.getCurrentContent().getPlainText();
    if (text.length > 0 || isFocused) return null;
  
  
    return (
      <Box position="absolute" top={1} left={10} pointerEvents="none" style={{ width: '100%', opacity: 0.55 }}>
        {type === "photo" ? (
            <>
                {/* <Typography sx={{fontWeight:500, fontSize:'16px', textAlign: 'left'}}>
                사진을 추가하고, 해당 사진에 대한 글을
                </Typography> */}
                <Typography sx={{fontWeight:500, fontSize:'16px', textAlign: 'left'}}>
               나의 생각을 공유해 주세요! 
                </Typography>
                <Typography sx={{fontWeight:500, fontSize:'16px', textAlign: 'left'}}>
                <span style={{ color: '#1173FF' }}>#태그</span>를 추가할 수도 있어요.
                </Typography>
            </>
        ) : (
          <>
            <Typography sx={{fontWeight:500, fontSize:'16px', textAlign: 'left'}}>
              작품에 대한 생각을 공유해 주세요!
            </Typography>
            <Typography sx={{fontWeight:500, fontSize:'16px', textAlign: 'left'}}>
              <span style={{ color: '#1173FF' }}>#태그</span>를 추가할 수도 있어요.
            </Typography>
          </>
        )}
      </Box>
    );
  };